import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Base64 } from "js-base64";
import PasswordValidator from "password-validator";

const passwordSchema = new PasswordValidator();

passwordSchema
  .is()
  .min(8)
  .is()
  .max(64)
  .has()
  .not()
  .spaces()
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits();

const passwordErrorsMessages = {
  min: "Password should be at least 8 charachters long",
  max: "Password should be be a maximum of 64 characters long",
  uppercase: "Password should have uppercase characters",
  lowercase: "Password should have lowercase characters",
  digits: "Password should contain digits",
  spaces: "Password should not contain spaces",
  blank: "Password should not be blank",
};

export const ResetPassword = () => {
  const { handleResetPassword } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);
  const [token, setToken] = useState("");
  const { Title } = Typography;
  const [form] = Form.useForm();

  useEffect(() => {
    if (searchParams.get("auth")) {
      const token = searchParams.get("auth");
      if (token) {
        setToken(token);
      } else {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    setIsReadyToSubmit(!!(password1 && password2));
  }, [password1, password2]);

  const onFinish = () => {
    if (
      password1 !== "" &&
      password2 !== "" &&
      password1 === password2 &&
      token
    ) {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d~!@#$%^&*()\-_=+\[\]{}|;:'",.<>]{8,64}$/;
      if (!passwordRegex.test(password1)) {
        setIsReadyToSubmit(false);
        return;
      }
      const newPasswordLoop1 = Base64.encode(password1);
      const newPassword = Base64.encode(newPasswordLoop1);
      handleResetPassword(token, newPassword);
    }
  };

  const validatePassword = ({
    instance,
    allowToSkip,
  }: {
    instance: any;
    allowToSkip: boolean;
  }) => {
    if (!instance.password) {
      if (allowToSkip) {
        return [];
      } else {
        return [
          {
            key: "password",
            keyword: "missing",
            message: passwordErrorsMessages["blank"],
          },
        ];
      }
    }

    const passwordErrors = passwordSchema.validate(instance.password, {
      list: true,
    });

    if (Array.isArray(passwordErrors) && passwordErrors.length) {
      return passwordErrors.map((error) => {
        return {
          key: "password",
          keyword: error,
          message:
            passwordErrorsMessages[
              error as keyof typeof passwordErrorsMessages
            ],
        };
      });
    } else {
      return [];
    }
  };
  return (
    <Row justify="center" className="login__row" gutter={16}>
      <Col md={24} xs={24} sm={24} lg={10} xl={10} className="login__col">
        <Card title={"Instructions"} hoverable={false} bordered={false}>
          <div style={{ textAlign: "justify" }}>
            Your new password must be between 8 and 64 characters long, include
            both uppercase and lowercase letters, contain digits, must not have
            spaces or be left blank, must not contain sequential or repetitive
            characters. New password should contain:
          </div>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={
                  "Letters [required]: a, b, c, d, e, f, g, … x, y, z, A, B, C, D, E, F, G, … X, Y, Z"
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={"Numbers [required]: 0, 1, 2, 3, 4, 5, 6, 7, 8, and 9"}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  "Symbols [optional]: ~, !, @, #, $, %, ^, &, *, (, ), -, _, =, +, [, {, ], }, , |. ;, :, ‘, “, ,, ., <, >"
                }
              />
            </List.Item>
          </List>
        </Card>
      </Col>
      <Col md={2} lg={2} xl={2} xs={24}>
        <Divider type="vertical" />
      </Col>
      <Col md={24} xs={24} sm={24} lg={10} xl={10} className="login__col">
        <Card title={"Reset Your Password"} hoverable={false} bordered={false}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="New password"
              className="collections-form__form-item"
              name="password1"
              rules={[
                { required: true, message: "Please input your password" },
                ({}) => ({
                  validator(_, value) {
                    const resultValidation = validatePassword({
                      instance: { password: value },
                      allowToSkip: false,
                    });
                    if (resultValidation && resultValidation.length === 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        resultValidation.map((e) => e.message).join(", ")
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                value={password1}
                autoComplete="new-password"
                placeholder="New password"
                onChange={(e) => setPassword1(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="password2"
              label="Repeat new password"
              className="collections-form__form-item"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Repeat new password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                autoComplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  type="default"
                  onClick={() => window.location.replace(origin)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={onFinish}
                  disabled={!isReadyToSubmit}
                  style={{ marginLeft: "20px" }}
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
