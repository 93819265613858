import React from "react";
import { Col, Result, Row } from "antd";
import { Link } from "react-router-dom";

const supportPage = `https://quattroapps.app/?token=${localStorage.getItem(
  "auth"
)}`;

export const HelpPage = () => {
  return (
    <Row gutter={[24, 0]} align="top" justify="center">
      <Col>
        <Result
          title="Please reach out to our Support team"
          extra={
            <Link to={supportPage} target="_blank">
              Open Support Page
            </Link>
          }
        />
      </Col>
    </Row>
  );
};
