import { UserInfoProps } from "../interfaces/interfaces";

export const ALERT_INITIAL_STATE = { status: false, message: "" };

export const USER_INITIAL_STATE: UserInfoProps = {
  active: true,
  id: "",
  name: "",
  username: "",
  Client: {
    active: true,
    description: "",
    externalId: "",
    id: "",
    name: "",
    SoftwareModules: [
      {
        active: true,
        description: "",
        domain: "",
        id: "",
        name: "",
        ClientSoftwareModules: {
          ClientId: "",
          SoftwareModulesId: "",
          active: true,
          id: "",
        },
      },
    ],
  },
};

export const PRODUCT_LIST_INITIAL_STATE = [
  {
    product: {
      id: "",
      name: "",
      host: "",
      server: "",
      guide: "",
      dashboard: "",
    },
  },
];
