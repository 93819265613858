import React from "react";
import desktopLogo from "../../utils/assets/images/company_logo.png";
import mobileLogo from "../../utils/assets/images/company_logo_mobile.png";
import { Grid } from "antd";

export const Logo = ({ mobile }: { mobile: boolean }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  if (xs || mobile) {
    return (
      <img src={mobileLogo} alt="Company Logo" className="logo-img-mobile" />
    );
  } else {
    return <img src={desktopLogo} alt="Company Logo" className="logo-img" />;
  }
};
