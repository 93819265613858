import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";

const validateEmail = (email: string) => {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const ResetPasswordModal = () => {
  const [form] = Form.useForm();
  const [username, setUsername] = React.useState("");
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const {
    isModalResetPasswordVisible,
    openCloseModalResetPassword,
    handleSendResetPassword,
  } = useContext(AuthContext);
  const [hasSentPasswordEmail, setHasSentPasswordEmail] = React.useState(false);

  useEffect(() => {
    form.setFieldsValue({ username: "" });
  }, []);

  useEffect(() => {
    setIsReadyToSubmit(!!username);
    if (username) {
      validateEmail(username) ? setIsValidEmail(true) : setIsValidEmail(false);
    } else {
      setIsValidEmail(false);
    }
  }, [username]);

  const onFinish = () => {
    if (username !== "" && isValidEmail) {
      handleSendResetPassword({
        username,
        next: () => {
          setHasSentPasswordEmail(true);
          form.setFieldsValue({ username: "" });
        },
      });
    }
  };
  return (
    <Modal
      title="Forgot Password?"
      open={isModalResetPasswordVisible}
      onCancel={() => openCloseModalResetPassword(false)}
      footer={null}
    >
      {hasSentPasswordEmail ? (
        <Row justify="center">
          <Col span={20}>
            <Typography>
              An email with password reset instructions has been sent to your
              email address.
            </Typography>
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => {
                  openCloseModalResetPassword(false);
                  setHasSentPasswordEmail(false);
                  setUsername("");
                }}
              >
                Accept
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <>
            Please enter your username, and instructions for resetting your
            password will be sent to your email address.
          </>
          <Col span={20}>
            <Form layout="vertical" form={form}>
              <Form.Item
                label="Username"
                name="username"
                className="collections-form__form-item"
                rules={[
                  { required: true, message: "Please input your username" },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  value={username}
                  type="email"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    type="default"
                    onClick={() => openCloseModalResetPassword(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={onFinish}
                    disabled={!(isReadyToSubmit && isValidEmail)}
                    style={{ marginLeft: "20px" }}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
