import { Button } from "antd";
import Typography from "antd/es/typography";
import React from "react";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph } = Typography;

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title level={2}>Privacy Policy</Title>
      <Paragraph>
        Quattro Software ("the Company") operates
        https://quicbooks.quattroapps.app ("the Website") and may operate
        Collections web app ("the Application"). This page informs you of our
        policies regarding the collection, use, and disclosure of Personal
        Information we receive from users of the Website and Application.
      </Paragraph>
      <Title level={4}>1. Information Collection and Use</Title>
      <Paragraph>
        1.1. While using our Website or Application, we may ask you to provide
        us with certain personally identifiable information that can be used to
        contact or identify you. Personally identifiable information may
        include, but is not limited to, your name, email address, postal
        address, and phone number ("Personal Information").
      </Paragraph>
      <Paragraph>
        1.2. We may use your Personal Information to contact you with
        newsletters, marketing, or promotional materials and other information
        that may be of interest to you.
      </Paragraph>
      <Title level={4}>2. Log Data</Title>
      <Paragraph>
        2.1. Like many site operators, we collect information that your browser
        sends whenever you visit our Website or Application ("Log Data"). This
        Log Data may include information such as your computer's Internet
        Protocol ("IP") address, browser type, browser version, and other
        statistics.
      </Paragraph>

      <Title level={4}>3. Cookies</Title>
      <Paragraph>
        3.1. Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and stored on your computer's hard drive.
      </Paragraph>
      <Paragraph>
        3.2. Like many sites, we use "cookies" to collect information. You can
        instruct your browser to refuse all cookies or to indicate when a cookie
        is being sent. However, if you do not accept cookies, you may not be
        able to use some portions of our Website or Application.
      </Paragraph>
      <Title level={4}>4. Security</Title>
      <Paragraph>
        4.1. The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </Paragraph>
      <Title level={4}>5. Changes to This Privacy Policy</Title>
      <Paragraph>
        5.1. This Privacy Policy is effective as of 02/20/2024. We reserve the
        right to update or change our Privacy Policy at any time, and you should
        check this Privacy Policy periodically. Your continued use of the
        Website or Application after we post any modifications to the Privacy
        Policy on this page will constitute your acknowledgment of the
        modifications and your consent to abide and be bound by the modified
        Privacy Policy.
      </Paragraph>
      <Title level={4}>6. Contact Us</Title>
      <Paragraph>
        6.1. If you have any questions about these Terms, please contact us at
        432-363-4009.
      </Paragraph>
      <Button type="link" onClick={() => navigate("/login")}>
        Back to Login
      </Button>
    </>
  );
};
