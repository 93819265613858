import React, { useContext, useEffect } from "react";
import { Layout, message } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import AuthContext from "../context/AuthContext";
import { Logo } from "../components/ui/Logo";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicLayout = ({ children }: Props) => {
  const { authError, authSuccess } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (authSuccess.status) success(authSuccess.message);
  }, [authSuccess.status]);

  useEffect(() => {
    if (authError.status) error(authError.message);
  }, [authError]);

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  return (
    <Layout className="public-layout">
      {contextHolder}
      <Header className="public-layout__header">
        <div />
        <Logo mobile={false} />
      </Header>
      <Content className="public-layout__content">
        <div className="public-layout__content__child">{children}</div>
      </Content>
      <Footer className="public-layout__footer">
        ©Copyright{" "}
        <a href="https://quattroapps.com" target="_blank" rel="noreferrer">
          Quattro Software
        </a>
        , {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};
