import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { Base64 } from "js-base64";
import { isExpired, decodeToken } from "react-jwt";
import { useRequest } from "../hooks";
import {
  ALERT_INITIAL_STATE,
  PRODUCT_LIST_INITIAL_STATE,
  USER_INITIAL_STATE,
} from "../utils/data";
import { UserInfoProps, UserProductProps } from "../interfaces/interfaces";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface TokenProps {
  userAlias: string;
  authToken: string;
  UserId: string;
}
const AuthProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoProps>(USER_INITIAL_STATE);
  const [userId, setUserId] = useState<null | string>(null);
  const [userProducts, setUserProducts] = useState<UserProductProps[]>(
    PRODUCT_LIST_INITIAL_STATE
  );
  const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] =
    useState(false);
  const [isModalResetPasswordVisible, setIsModalResetPasswordVisible] =
    useState(false);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("auth");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      dataUser && setUserId(dataUser.UserId);
    }
  }, [localStorage.getItem("auth")]);

  useEffect(() => {
    validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isError, isSuccess]);

  const getUserId = () => {
    let result = null;
    const tokenCoded = localStorage.getItem("auth");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser) result = dataUser?.UserId;
    }
    return result;
  };

  const logoutHandler = async (next: () => void) => {
    setIsLoading(true);
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    setIsLoading(false);
    next();
  };

  const loginHandler = (username: string, pass: string) => {
    setIsLoading(true);
    try {
      const loop1Pass = Base64.encode(pass);
      const loop2Pass = Base64.encode(loop1Pass);
      const data = {
        username,
        password: loop2Pass,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
      };
      handleRequest<{ data: { token: string }; success: boolean }>({
        endpoint: "login",
        options,
        onSuccess: (response) => {
          if (response.success) {
            localStorage.setItem("auth", response.data.token);
            const dataUser: TokenProps | null = decodeToken(
              response.data.token
            );
            if (dataUser) navigate(`main/${dataUser.UserId}`);
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler(() => {});
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("auth");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
    } else {
      return false;
    }
    return true;
  };

  const handleChangePassword = ({
    data,
    next,
  }: {
    data: {
      currentPassword: string;
      newPassword: string;
    };
    next: () => void;
  }) => {
    try {
      if (userId) {
        setIsLoading(true);
        let options: RequestInit = {
          method: "PUT",
          body: JSON.stringify(data),
        };
        handleRequest({
          endpoint: `user-password/${userId}`,
          options,
          onSuccess: (response) => {
            setIsLoading(false);
            if (response) {
              setIsSuccess({ status: true, message: "Password changed" });
              next();
            }
          },
          onError: (e) => {
            setIsLoading(false);
            setIsError({ status: true, message: e.message });
          },
        });
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };
  const handleSendResetPassword = ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => {
    try {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        headers: {
          ["x-origin"]: "login.quattroapps.app",
        },
        body: JSON.stringify({ username }),
      };
      handleRequest({
        endpoint: `forgot-password`,
        options,
        onSuccess: (response) => {
          setIsLoading(false);
          if (response) {
            setIsSuccess({ status: true, message: "Password Sent" });
            next();
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: e?.message });
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const handleResetPassword = (token: string, newPassword: string) => {
    try {
      setIsLoading(true);
      let options: RequestInit = {
        method: "PUT",
        body: JSON.stringify({ newPassword }),
        headers: {
          ["x-origin"]: "login.quattroapps.app",
        },
      };
      handleRequest<{ data: string }>({
        endpoint: `reset-password/?auth=${token}`,
        options,
        onSuccess: (response) => {
          setIsLoading(false);
          if (response) {
            window.location.replace(response.data);
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: e.message });
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const getUserInfo = (idUser: string) => {
    try {
      setIsLoading(true);
      handleRequest<{ data: UserInfoProps }>({
        endpoint: `user/${idUser}`,
        onSuccess: (response) => {
          setIsLoading(false);
          if (response) {
            setUserInfo(response.data);
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const getUserProducts = (idUser: string) => {
    setIsLoading(true);
    handleRequest<{ data: any }>({
      endpoint: `product/user/${idUser}`,
      onSuccess: (response) => {
        setIsLoading(false);
        if (response) {
          setUserProducts(response.data);
        }
      },
      onError: (e) => {
        setIsLoading(false);
        setUserProducts([]);
        setIsError({ status: true, message: e.message });
      },
    });
    setIsLoading(false);
  };

  const handleLoginApp = (product: UserProductProps["product"]) => {
    setIsLoading(true);
    try {
      if (product.host) {
        const token = localStorage.getItem("auth");
        window.open(`${product.host}?token=${token}`);
      }
      setIsLoading(false);
    } catch (e) {}
  };

  return (
    <AuthContext.Provider
      value={{
        userId,
        isLoggedIn,
        authSuccess: isSuccess,
        authError: isError,
        authLoading: isLoading,
        userInfo,
        userProducts,
        isModalChangePasswordVisible,
        isModalResetPasswordVisible,
        openCloseModalPassword: (value) =>
          setIsModalChangePasswordVisible(value),
        openCloseModalResetPassword: (value) =>
          setIsModalResetPasswordVisible(value),
        handleChangePassword,
        handleSendResetPassword,
        handleResetPassword,
        getUserProducts,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        validateToken,
        getUserInfo,
        handleLoginApp,
        getUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
