import React from "react";
import {
  Login,
  HelpPage,
  PrivacyPolicy,
  Settings,
  TermsOfService,
  MainPage,
  NotFoundPage,
  ResetPassword,
} from "../pages";

const routes = {
  initial: "/",
  login: "/login",
  resetPassword: "/reset-password",
  main: "/main/:userId",
  settings: "/settings/:idClient",
  help: "/help",
  terms: "/terms",
  privacy: "/privacy",
  notFound: "/*",
};

export const AppPages = [
  { path: routes.login, protected: false, component: <Login /> },
  {
    path: routes.resetPassword,
    protected: false,
    component: <ResetPassword />,
  },
  { path: routes.terms, protected: false, component: <TermsOfService /> },
  { path: routes.initial, protected: true, component: <NotFoundPage /> },
  { path: routes.main, protected: true, component: <MainPage /> },
  { path: routes.terms, protected: true, component: <TermsOfService /> },
  { path: routes.privacy, protected: false, component: <PrivacyPolicy /> },
  { path: routes.privacy, protected: true, component: <PrivacyPolicy /> },
  { path: routes.settings, protected: true, component: <Settings /> },
  { path: routes.help, protected: true, component: <HelpPage /> },
  { path: routes.notFound, protected: true, component: <NotFoundPage /> },
  { path: routes.notFound, protected: false, component: <NotFoundPage /> },
];
