import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import "./globals.css";
import { ConfigProvider } from "antd";
import { AppPages } from "./routes/Routes";
import { config } from "./utils/theme/config";
import { ProtectedRoute } from "./routes/ProtectedRoutes";
import { PublicRoute } from "./routes/PublicRoutes";
import "./assets/sass/main.scss";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ConfigProvider {...config}>
        <Routes>
          {AppPages?.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <ProtectedRoute>{route.component}</ProtectedRoute>
                ) : (
                  <PublicRoute>{route.component}</PublicRoute>
                )
              }
            />
          ))}
        </Routes>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default App;
