import React from "react";
import {
  ALERT_INITIAL_STATE,
  PRODUCT_LIST_INITIAL_STATE,
  USER_INITIAL_STATE,
} from "../utils/data";
import {
  SoftwareModuleProps,
  UserInfoProps,
  UserProductProps,
} from "../interfaces/interfaces";

interface Props {
  userId: string | null;
  isLoggedIn: boolean;
  authSuccess: { status: boolean; message: string };
  authError: { status: boolean; message: string };
  authLoading: boolean;
  userInfo: UserInfoProps;
  userProducts: UserProductProps[];
  isModalChangePasswordVisible: boolean;
  isModalResetPasswordVisible: boolean;
  openCloseModalPassword: (value: boolean) => void;
  openCloseModalResetPassword: (value: boolean) => void;
  handleChangePassword: ({
    data,
    next,
  }: {
    data: {
      currentPassword: string;
      newPassword: string;
    };
    next: () => void;
  }) => void;
  handleSendResetPassword: ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => void;
  handleResetPassword: (token: string, newPassword: string) => void;
  getUserProducts: (username: string) => void;
  onLogin: (username: string, password: string) => void;
  onLogout: (next: () => void) => void;
  validateToken: () => boolean;
  getUserInfo: (idUser: string) => void;
  handleLoginApp: (module: SoftwareModuleProps) => void;
  getUserId: () => string | null;
}

const AuthContext = React.createContext<Props>({
  userId: null,
  isLoggedIn: false,
  authSuccess: ALERT_INITIAL_STATE,
  authError: ALERT_INITIAL_STATE,
  authLoading: false,
  userInfo: USER_INITIAL_STATE,
  userProducts: PRODUCT_LIST_INITIAL_STATE,
  isModalChangePasswordVisible: false,
  isModalResetPasswordVisible: false,
  openCloseModalPassword: () => {},
  openCloseModalResetPassword: () => {},
  handleChangePassword: () => {},
  handleSendResetPassword: () => {},
  handleResetPassword: () => {},
  getUserProducts: () => {},
  onLogin: () => {},
  onLogout: () => {},
  validateToken: () => false,
  getUserInfo: () => null,
  handleLoginApp: () => null,
  getUserId: () => null,
});

export default AuthContext;
