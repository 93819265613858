import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { onLogout, getUserId } = useContext(AuthContext);
  useEffect(() => {
    const userId = getUserId();
    if (userId) {
      navigate(`/main/${userId}`);
    } else {
      onLogout(() => navigate("/login"));
    }
  }, []);
  return <></>;
};
