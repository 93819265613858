import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { Button, Dropdown, Layout, Tooltip, message, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import AuthContext from "../context/AuthContext";
import { MenuOutlined } from "@ant-design/icons";
import { Logo } from "../components/ui/Logo";
import { MenuProps } from "antd/lib/menu";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const { authError, authSuccess, onLogout, openCloseModalPassword } =
    useContext(AuthContext);
  const hasSucceded = useRef(false);
  const hasFailed = useRef(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (authSuccess.status && !hasSucceded.current) {
      success(authSuccess.message);
      hasSucceded.current = true;
    }
  }, [authSuccess.status]);

  useEffect(() => {
    if (authError.status && !hasFailed.current) {
      error(authError.message);
      hasFailed.current = true;
    }
  }, [authError]);

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };
  const items: MenuProps["items"] = [
    {
      label: "Change Password",
      key: "change-password",
      onClick: () => openCloseModalPassword(true),
    },
    {
      label: "Logout",
      key: "logout",
      onClick: () => onLogout(() => navigate("/login")),
    },
  ];

  return (
    <Layout className="public-layout">
      {contextHolder}
      <Header className="protected-layout__header">
        <div />
        <Logo mobile={false} />
        <div>
          <Dropdown menu={{ items }}>
            <Button
              type="default"
              style={{ marginRight: "1rem" }}
              icon={<MenuOutlined />}
            ></Button>
          </Dropdown>
        </div>
      </Header>
      <Content className="public-layout__content">
        <div className="public-layout__content__child">{children}</div>
      </Content>
      <Footer className="public-layout__footer">
        ©Copyright Quattro Software, {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};
