import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card, List, Result, Spin, Typography } from "antd";
import Styles from "./MainPage.module.css";
import { ChangePasswordModal } from "../../components";
const { Paragraph, Text } = Typography;

export const MainPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const {
    getUserProducts,
    userProducts,
    onLogout,
    authLoading,
    handleLoginApp,
  } = useContext(AuthContext);

  useEffect(() => {
    if (userId) getUserProducts(userId);
  }, [userId]);

  return (
    <>
      <>
        {userProducts && userProducts.length !== 0 ? (
          <Card
            title={<strong>Your Apps</strong>}
            bordered
            className={Styles.Card}
          >
            <List
              className={Styles.List}
              rowKey={"id"}
              loading={authLoading}
              dataSource={userProducts.map((product) => product.product)}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  className={Styles.ItemList}
                  onClick={() => item.host && handleLoginApp(item as any)}
                >
                  {item.name}
                </List.Item>
              )}
            />
          </Card>
        ) : (
          <Result
            status="warning"
            title={
              <span
                style={{
                  color: "#FFF",
                }}
              >
                No Apps associated with your user
              </span>
            }
            subTitle={
              <span
                style={{
                  color: "#FFF",
                }}
              >
                Please contact our sales team to access to your Applications
              </span>
            }
            extra={[
              <Button
                type="link"
                key="console"
                onClick={() => {
                  window.open("https://support.quattroapps.app");
                }}
              >
                Go to our Support Site
              </Button>,
              <Button
                key="buy"
                onClick={() => onLogout(() => navigate("/login"))}
              >
                Logout
              </Button>,
            ]}
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                    color: "#FFF",
                  }}
                >
                  You are not allowed to see this content due to the one
                  following errors
                </Text>
              </Paragraph>
              <Paragraph
                style={{
                  color: "#FFF",
                }}
              >
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                Your account has been frozen.
              </Paragraph>
              <Paragraph
                style={{
                  color: "#FFF",
                }}
              >
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                Your account is not ready to login
              </Paragraph>
            </div>
          </Result>
        )}
      </>
      <ChangePasswordModal />
    </>
  );
};
