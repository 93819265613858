import { Button } from "antd";
import Typography from "antd/es/typography";
import React from "react";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph } = Typography;

export const TermsOfService = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title level={2}>Terms of Service</Title>
      <Paragraph>
        This document outlines the terms and conditions ("Terms") that govern
        your use of Collections webapp ("the Service") provided by Quattro
        Software LLC ("the Company").
      </Paragraph>
      <Paragraph>
        By accessing or using the Service, you agree to be bound by these Terms.
        If you disagree with any part of the Terms, you may not access the
        Service.
      </Paragraph>
      <Title level={4}>1. Use of the Service</Title>
      <Paragraph>
        1.1. You agree to use the Service only for lawful purposes and in
        accordance with these Terms.
      </Paragraph>
      <Title level={4}>2. User Accounts</Title>
      <Paragraph>
        2.1. You may be required to create an account to use certain features of
        the Service. You are responsible for maintaining the confidentiality of
        your account credentials.
      </Paragraph>
      <Paragraph>
        2.2. You agree to provide accurate and complete information when
        creating your account and to update your information promptly if it
        changes.
      </Paragraph>
      <Title level={4}>3. Intellectual Property</Title>
      <Paragraph>
        3.1. The Service and its original content, features, and functionality
        are owned by the Company and are protected by international copyright,
        trademark, patent, trade secret, and other intellectual property or
        proprietary rights laws.
      </Paragraph>
      <Title level={4}>4. Limitation of Liability</Title>
      <Paragraph>
        4.1. The Company shall not be liable for any indirect, incidental,
        special, consequential, or punitive damages arising out of or relating
        to your use of the Service.
      </Paragraph>
      <Title level={4}>5. Changes to Terms</Title>
      <Paragraph>
        5.1. The Company reserves the right to modify or replace these Terms at
        any time. It is your responsibility to review these Terms periodically
        for changes. Your continued use of the Service after any modifications
        to the Terms constitutes acceptance of those changes.
      </Paragraph>
      <Title level={4}>6. Contact Us</Title>
      <Paragraph>
        6.1. If you have any questions about these Terms, please contact us at
        432-363-4009.
      </Paragraph>
      <Button type="link" onClick={() => navigate("/login")}>
        Back to Login
      </Button>
    </>
  );
};
