import React, { useContext } from "react";
import { PublicLayout } from "./PublicLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicRoute = ({ children }: Props) => {
  const { validateToken, userId } = useContext(AuthContext);
  if (validateToken() && userId) {
    return <Navigate to={`/main/${userId}`} replace />;
  } else {
    return <PublicLayout>{children}</PublicLayout>;
  }
};
